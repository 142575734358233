import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();
	if (!config.public.googleTagManagerId) {
		return;
	}

	nuxtApp.vueApp.use(
		createGtm({
			id: config.public.googleTagManagerId,
			defer: false,
			compatibility: false,
			vueRouter: useRouter(),
			trackOnNextTick: false,
			parentElement: document.head,
		}),
	);
});
