/**
 * Directive to replace native HTML anchor tags with Vue routes,
 * e.g. in dynamic HTML content from a WYSIWYG editor.
 */

import { nextTick } from 'vue';
import availableLanguages from '@/config/languages';

export default defineNuxtPlugin((nuxtApp) => {
	const router = useRouter();
	const i18n = nuxtApp.$i18n;

	nuxtApp.vueApp.directive('parse-links', {
		created(el) {
			el.$componentUpdated = () => updateLinks(el, i18n, router);
			el.$destroy = () => removeListeners(getRouterLinks(el), router);

			updateLinks(el, i18n, router);
		},

		componentUpdated: (el) => el.$componentUpdated(),
		unmounted: (el) => el.$destroy(),
	});
});

/**
 * On component update, remove the event listeners on its child links and
 * re-add them after nextTick, since the links may have changed.
 *
 * @param {*} el The element
 * @param {*} i18n The i18n object
 * @param {*} router The router object
 *
 * @returns {undefined}
 */
const updateLinks = (el, i18n, router) => {
	let routerLinks = getRouterLinks(el);
	localizeRouterLinks(routerLinks, i18n);

	removeListeners(routerLinks, router);

	nextTick(() => {
		routerLinks = getRouterLinks(el);
		localizeRouterLinks(routerLinks, i18n);

		addListeners(routerLinks, router);
	});
};

/**
 * Localize the links that are handled by the router. This way,
 * if the end user forgets to add the language within the CMS the link
 * still works.
 *
 * @param {*} links The links to localize
 * @param {*} i18n The i18n object
 *
 * @returns {undefined}
 */
const localizeRouterLinks = (links, i18n) => {
	links.forEach((link) => {
		let href = link.getAttribute('href');
		href = href[0] === '/' ? href.substring(1) : href;
		link.setAttribute('href', localize(href, i18n.locale.value));
	});
};

/**
 * Get the links to be handled by the router.
 *
 * @param {*} el The parent element of the links
 *
 * @returns {Array} The router links
 */
const getRouterLinks = (el) => {
	const links = Array.from(el.getElementsByTagName('a'));

	return links.filter((item) => shouldBeHandledByRouter(item.getAttribute('href')));
};

/**
 * Add navigate event to the links
 *
 * @param {*} links The links
 * @param {*} router The router object
 *
 * @returns {undefined}
 */
const addListeners = (links, router) => {
	links.forEach((link) => {
		link.addEventListener('click', (event) => navigate(event, router), false);
	});
};

/**
 * Remove navigate event from the links
 *
 * @param {*} links The links
 * @param {*} router The router object
 *
 * @returns {undefined}
 */
const removeListeners = (links, router) => {
	links.forEach((link) => {
		link.removeEventListener('click', (event) => navigate(event, router), false);
	});
};

/**
 * Prevent default link action and use the router instead
 *
 * @param {*} event The event object
 * @param {*} router The router object
 *
 * @returns {undefined}
 */
const navigate = (event, router) => {
	const href = event.currentTarget.getAttribute('href');
	event.preventDefault();
	event.metaKey ? window.open(href, '_blank', 'noopener noreferrer') : router.push(href);
};

/**
 * Determine if a given href should be handled by the router or not
 *
 * @param {String} href The href
 *
 * @returns {Boolean} True if the router handles it
 */
const shouldBeHandledByRouter = (href) => {
	return href && !['http', 'tel', 'mail'].some((word) => href.startsWith(word)) && !href.startsWith('#');
};

/**
 * Localize a filename
 *
 * @param {String} filename The filename
 * @param {String} locale The locale
 *
 * @returns {String} The localized filename
 */
const localize = (filename, locale) => {
	if (hasLocale(filename)) {
		return `/${filename}`;
	}

	const localePath = isDefaultLocale(locale) ? `/${filename}` : `/${locale}/${filename}`;

	return localePath.replace(/(\w+)\/$/g, '$1');
};

/**
 * Check if a filename starts with one of the configured locales
 *
 * @param {string} filename The filename
 *
 * @returns {boolean} True when it starts with a locale code
 */
const hasLocale = (filename) => {
	const localeCodes = availableLanguages.map((lang) => lang.code);
	if (localeCodes.includes(filename.replace(/^\/(\w+)/g, '$1').substring(0, 2))) {
		return true;
	}

	return false;
};

/**
 * Check if the argument localeCode is configured as the App default locale.
 *
 * @param {string} localeCode The locale
 * @returns {boolean} True when locale is the default locale
 */
const isDefaultLocale = (localeCode) => localeCode === availableLanguages[0].code;
