/**
 * Get the CMS filename from the current route
 *
 * @param {*} route The route object
 * @param {String} locale The current locale
 *
 * @returns {String} The filename
 */
export const useFilename = (route, locale) => {
	let filename = route.path.replace(/^\/|\/$/g, '');

	if (route.path === `/${locale}` || route.path.startsWith(`/${locale}/`)) {
		filename = filename.substring(3);
	}

	return filename;
};
