
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "nl",
  "pl"
]

export const localeLoaders = {
  "de": [{ key: "../config/language/de.js", load: () => import("../config/language/de.js" /* webpackChunkName: "locale__vercel_path0_config_language_de_js" */), cache: true }],
  "en": [{ key: "../config/language/en.js", load: () => import("../config/language/en.js" /* webpackChunkName: "locale__vercel_path0_config_language_en_js" */), cache: true }],
  "nl": [{ key: "../config/language/nl.js", load: () => import("../config/language/nl.js" /* webpackChunkName: "locale__vercel_path0_config_language_nl_js" */), cache: true }],
  "pl": [{ key: "../config/language/pl.js", load: () => import("../config/language/pl.js" /* webpackChunkName: "locale__vercel_path0_config_language_pl_js" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "__i18n_config_js_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.js",
  "locales": [
    {
      "code": "de",
      "files": [
        "config/language/de.js"
      ]
    },
    {
      "code": "en",
      "files": [
        "config/language/en.js"
      ]
    },
    {
      "code": "nl",
      "files": [
        "config/language/nl.js"
      ]
    },
    {
      "code": "pl",
      "files": [
        "config/language/pl.js"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "config/language/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "contact": {
      "en": "/contact",
      "de": "/kontakt",
      "nl": "/contact",
      "pl": "/contact"
    },
    "events/index": {
      "en": "/events",
      "de": "/events",
      "nl": "/events",
      "pl": "/events"
    },
    "events/[item]": {
      "en": "/events/[item]",
      "de": "/events/[item]",
      "nl": "/events/[item]",
      "pl": "/events/[item]"
    },
    "faq": {
      "en": "/faq",
      "de": "/faq",
      "nl": "/faq",
      "pl": "/faq"
    },
    "gallery": {
      "en": "/gallery",
      "de": "/galerie",
      "nl": "/galerij",
      "pl": "/gallery"
    },
    "highlights": {
      "en": "/location",
      "de": "/standort",
      "nl": "/locatie",
      "pl": "/location"
    },
    "index": {
      "en": "/",
      "de": "/",
      "nl": "/",
      "pl": "/"
    },
    "meetingform": {
      "en": "/meeting-form",
      "de": "/meeting-formular",
      "nl": "/vergaderformulier",
      "pl": "/meeting-form"
    },
    "meetingpackages/index": {
      "en": "/meeting-packages",
      "de": "/tagungspakete",
      "nl": "/vergaderarrangementen",
      "pl": "/meeting-packages"
    },
    "meetingpackages/[item]": {
      "en": "/meeting-packages/[item]",
      "de": "/tagungspakete/[item]",
      "nl": "/vergaderarrangementen/[item]",
      "pl": "/meeting-packages/[item]"
    },
    "meetingrooms/index": {
      "en": "/meeting-rooms",
      "de": "/tagungsraume",
      "nl": "/vergaderzalen",
      "pl": "/meeting-rooms"
    },
    "meetingrooms/[item]": {
      "en": "/meeting-rooms/[item]",
      "de": "/tagungsraume/[item]",
      "nl": "/vergaderzalen/[item]",
      "pl": "/meeting-rooms/[item]"
    },
    "packages/index": {
      "en": "/special-offers",
      "de": "/arrangements",
      "nl": "/arrangementen",
      "pl": "/special-offers"
    },
    "packages/[item]": {
      "en": "/special-offers/[item]",
      "de": "/arrangements/[item]",
      "nl": "/arrangementen/[item]",
      "pl": "/special-offers/[item]"
    },
    "privacy": {
      "en": "/privacy-policy",
      "de": "/datenschutz-und-cookies",
      "nl": "/privacybeleid",
      "pl": "/privacy-policy"
    },
    "reviews": {
      "en": "/reviews",
      "de": "/rezensionen",
      "nl": "/beoordelingen",
      "pl": "/reviews"
    },
    "rooms/index": {
      "en": "/rooms",
      "de": "/zimmer",
      "nl": "/kamers",
      "pl": "/rooms"
    },
    "rooms/[item]": {
      "en": "/rooms/[item]",
      "de": "/zimmer/[item]",
      "nl": "/kamers/[item]",
      "pl": "/rooms/[item]"
    },
    "sitemap": {
      "en": "/sitemap",
      "de": "/sitemap",
      "nl": "/sitemap",
      "pl": "/sitemap"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "files": [
      {
        "path": "config/language/de.js"
      }
    ]
  },
  {
    "code": "en",
    "files": [
      {
        "path": "config/language/en.js"
      }
    ]
  },
  {
    "code": "nl",
    "files": [
      {
        "path": "config/language/nl.js"
      }
    ]
  },
  {
    "code": "pl",
    "files": [
      {
        "path": "config/language/pl.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
