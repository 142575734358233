import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as meetingformyqklQBmRwxMeta } from "/vercel/path0/pages/meetingform.vue?macro=true";
import { default as _91item_93Z6s9hH0QMEMeta } from "/vercel/path0/pages/meetingpackages/[item].vue?macro=true";
import { default as indexdbYcUSRZncMeta } from "/vercel/path0/pages/meetingpackages/index.vue?macro=true";
import { default as _91item_93JX6JhcBuIMMeta } from "/vercel/path0/pages/meetingrooms/[item].vue?macro=true";
import { default as indexCkXxfvKxLjMeta } from "/vercel/path0/pages/meetingrooms/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as _91item_93bm9CCcgfkyMeta } from "/vercel/path0/pages/rooms/[item].vue?macro=true";
import { default as indexshyPbhYt8IMeta } from "/vercel/path0/pages/rooms/index.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___de",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___pl",
    path: "/pl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/kontakt",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___pl",
    path: "/pl/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "faq___de",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___nl",
    path: "/nl/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___pl",
    path: "/pl/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "gallery___de",
    path: "/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___en",
    path: "/en/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___nl",
    path: "/nl/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___pl",
    path: "/pl/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "highlights___de",
    path: "/standort",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___en",
    path: "/en/location",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___nl",
    path: "/nl/locatie",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___pl",
    path: "/pl/location",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingform___de",
    path: "/meeting-formular",
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: "meetingform___en",
    path: "/en/meeting-form",
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: "meetingform___nl",
    path: "/nl/vergaderformulier",
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: "meetingform___pl",
    path: "/pl/meeting-form",
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages-item___de",
    path: "/tagungspakete/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages-item___en",
    path: "/en/meeting-packages/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages-item___nl",
    path: "/nl/vergaderarrangementen/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages-item___pl",
    path: "/pl/meeting-packages/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages___de",
    path: "/tagungspakete",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages___en",
    path: "/en/meeting-packages",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages___nl",
    path: "/nl/vergaderarrangementen",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingpackages___pl",
    path: "/pl/meeting-packages",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms-item___de",
    path: "/tagungsraume/:item()",
    component: () => import("/vercel/path0/pages/meetingrooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms-item___en",
    path: "/en/meeting-rooms/:item()",
    component: () => import("/vercel/path0/pages/meetingrooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms-item___nl",
    path: "/nl/vergaderzalen/:item()",
    component: () => import("/vercel/path0/pages/meetingrooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms-item___pl",
    path: "/pl/meeting-rooms/:item()",
    component: () => import("/vercel/path0/pages/meetingrooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms___de",
    path: "/tagungsraume",
    component: () => import("/vercel/path0/pages/meetingrooms/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms___en",
    path: "/en/meeting-rooms",
    component: () => import("/vercel/path0/pages/meetingrooms/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms___nl",
    path: "/nl/vergaderzalen",
    component: () => import("/vercel/path0/pages/meetingrooms/index.vue").then(m => m.default || m)
  },
  {
    name: "meetingrooms___pl",
    path: "/pl/meeting-rooms",
    component: () => import("/vercel/path0/pages/meetingrooms/index.vue").then(m => m.default || m)
  },
  {
    name: "packages-item___de",
    path: "/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___en",
    path: "/en/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___nl",
    path: "/nl/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___pl",
    path: "/pl/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages___de",
    path: "/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___en",
    path: "/en/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___nl",
    path: "/nl/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___pl",
    path: "/pl/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy___de",
    path: "/datenschutz-und-cookies",
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/en/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___nl",
    path: "/nl/privacybeleid",
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___pl",
    path: "/pl/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "reviews___de",
    path: "/rezensionen",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___nl",
    path: "/nl/beoordelingen",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___pl",
    path: "/pl/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "rooms-item___de",
    path: "/zimmer/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "rooms-item___en",
    path: "/en/rooms/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "rooms-item___nl",
    path: "/nl/kamers/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "rooms-item___pl",
    path: "/pl/rooms/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "rooms___de",
    path: "/zimmer",
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "rooms___en",
    path: "/en/rooms",
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "rooms___nl",
    path: "/nl/kamers",
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "rooms___pl",
    path: "/pl/rooms",
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___de",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___pl",
    path: "/pl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  }
]