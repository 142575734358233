import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faArrowUp,
	faCalendarDays,
	faBars,
	faTimes,
	faStars,
	faPhone,
	faEnvelope,
	faFax,
	faConciergeBell,
	faRulerCombined,
	faBlanket,
	faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronDown, faChevronUp, faBed, faBell, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faArrowUp,
	faBed,
	faBell,
	faChevronDown,
	faPhone,
	faEnvelope,
	faFax,
	faCircleCheck,
	faChevronUp,
	faCalendarDays,
	faBars,
	faStars,
	faConciergeBell,
	faBlanket,
	faTimes,
	faFacebook,
	faInstagram,
	faLinkedin,
	faStar,
	faStarHalf,
	faRulerCombined,
	faUsers,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
